import {
  API_RETRY_CONFIG,
  HTTP_METHOD_DELETE,
  HTTP_METHOD_GET,
  HTTP_METHOD_POST,
  HTTP_METHOD_PUT,
} from "../constants";

import axios from "axios";
import { createMockInstance } from "../../mocks";
import { isRetryRequired } from "../utils/httpUtils";
import packageJson from "../../package.json";
import store from "@/store/store";
import { useToast } from "vue-toastification";

// import CryptoJS from "crypto-js";

const statusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  TooManyRequests: 429,
  InternalServerError: 500,
  BadRequest: 400,
};

function setCookie(name, value, days, minutesEnable = false) {
  let secure = "";
  var expires = "";
  let domain = "";
  if (days) {
    const date = new Date();
    if (minutesEnable) {
      date.setTime(date.getTime() + days * 60 * 1000);
    } else {
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    }
    expires = "; expires=" + date.toUTCString();
  }
  //commented if condition for rewrite testing
  // if (import.meta.env.VITE_APP_BUILD_RELEASE == "prod") {
  secure = "SameSite=strict; Secure";
  domain = "domain=.angelone.in;";
  // }
  // document.cookie = name + "=" + (value || "") + expires + "; path=/";
  document.cookie =
    name + "=" + value + "; " + expires + ";" + domain + "path=/;" + secure;
}

function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function deleteCookieWithDomain(name, domain) {
  document.cookie =
    name +
    "=; Path=/;" +
    (domain ? "Domain=" + domain + ";" : "") +
    "Expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

function getCookieValue(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// function getCookie(name) {
//   // encripted value
//   const value = getCookieValue(name);
//   if (value) {
//     var bytes = CryptoJS.AES.decrypt(value, "@nge|$p@rk2021");
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   }
// }

function clearAllCookie() {
  // var cookies = document.cookie.split("; ");
  // for (var c = 0; c < cookies.length; c++) {
  //   var d = window.location.hostname.split(".");
  //   while (d.length > 0) {
  //     var cookieBase =
  //       encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
  //       "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
  //       d.join(".") +
  //       " ;path=";
  //     var p = location.pathname.split("/");
  //     document.cookie = cookieBase + "/";
  //     while (p.length > 0) {
  //       document.cookie = cookieBase + p.join("/");
  //       p.pop();
  //     }
  //     d.shift();
  //   }
}
const appVersion = packageJson.version;

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Accept-Language": "en-US",
  "X-appVersion": `${appVersion}|${appVersion}`,
  "X-journeyType": getCookieValue("journey-type"),
};

const injectToken = (config) => {
  try {
    const token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
    // const token = getCookie(import.meta.env.VITE_APP_GUEST_COOKIE)?.GuestToken;
    if (
      token != null &&
      !(
        config.url.includes("/generateOTPPostCaptcha") ||
        config.url.includes("/verifyLoginOTP") ||
        config.url.includes("https://icanhazip.com")
      )
    ) {
      config["headers"]["Authorization"] = `Bearer ${token}`;
    }
    // config["headers"]["Access-Control-Allow-Origin"] = "*";
    return config;
  } catch (error) {
    showErrorMessage("Invalid Token", "token");
    throw new Error(error);
  }
};

// const injectSource = (config) => {
//   try {
//     config["headers"]["X-source"] = getCookieValue("source") || "spark-web";
//     return config;
//   } catch (error) {
//     // showErrorMessage("Invalid Source", "source");
//     // throw new Error(error);
//     config["headers"]["X-source"] = "spark-web";
//   }
// };

const injectSource = async (config) => {
  try {
    if (
      config.url.includes("/generateOTPPostCaptcha") ||
      config.url.includes("/verifyLoginOTP") ||
      config.url.includes("/request_ip")
    ) {
      config["baseURL"] = import.meta.env.VITE_LOGIN_API_BASE_URL;
      // config["headers"]["x-captcha"] = await getCapchaCode();
    } else if (config.url.includes("cams/available-banks")) {
      config["baseURL"] = import.meta.env.VITE_USER_JOURNEY_API;
      config["headers"]["X-source"] = getCookieValue("source") || "spark-web";
    } else {
      config["baseURL"] = import.meta.env.VITE_API_BASE_URL;
      // const curentLocation = window.location;
      if (store?.state?.features["nomineeBeforeEsign"] == true) {
        config["headers"]["X-source"] = getCookieValue("source") || "spark-web";
      }
      // else if (
      //   curentLocation.toString().includes("/nominees") &&
      //   !config.url.includes("/start") &&
      //   (store?.state?.features["nomineeBeforeEsign"] != undefined ||
      //     store?.state?.features["nomineeBeforeEsign"] != " " ||
      //     store?.state?.features["nomineeBeforeEsign"] != null) &&
      //   store?.state?.features["nomineeBeforeEsign"] == false &&
      //   store?.state?.features["nominee"] == true
      // ) {
      //   config["headers"]["X-source"] = "spark-modification";
      // }
      else {
        config["headers"]["X-source"] = getCookieValue("source") || "spark-web";
      }
    }

    return config;
  } catch (error) {
    // showErrorMessage("Invalid Source", "source");
    // throw new Error(error);
    config["headers"]["X-source"] = "spark-web";
  }
};

const showErrorMessage = (msg, id = "toastIdError") => {
  let toast = useToast();
  if (window.screen.width < 768) {
    if (msg) {
      store.commit("SET_SNACK_BAR", {
        text: msg,
        snackbar: true,
        timeout: 2000,
        multiLine: true,
      });
    } else {
      store.commit("SET_SNACK_BAR", {
        text: "Something went wrong",
        snackbar: true,
        timeout: 2000,
      });
    }
  } else {
    if (msg) {
      toast.error(msg, { id: id, timeout: 5000 });
    } else {
      toast.error("Something went wrong", { id: id });
    }
  }
};
const showInfoMessage = (msg, id = "toastIdInfo", timeout) => {
  let toast = useToast();
  if (window.screen.width < 768) {
    if (msg) {
      store.commit("SET_SNACK_BAR", {
        text: msg,
        snackbar: true,
        timeout: timeout,
        multiLine: true,
      });
    }
  } else {
    if (msg) {
      toast.info(msg, { id: id });
    }
  }
};
class HttpClient {
  constructor(c_headers = {}) {
    headers = {
      ...headers,
      ...c_headers,
      "X-journeyType": getCookieValue("journey-type"),
    };
    this.instance = null;
    this.b_url = "";
  }

  static getBaseUrl() {
    return this.b_url;
  }

  get http() {
    return this.instance != null ? this.instance : this.init();
  }

  init() {
    // "https://services-kyc2-dev.angelbroking.com",
    const http = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers,
    });
    if (import.meta.env.VITE_ENABLE_MOCK_RESPONSE === "true") {
      createMockInstance(http);
    }
    //eslint-disable-next-line
    http.interceptors.request.use(injectToken, (error) => {
      Promise.reject(error);
    });

    //eslint-disable-next-line
    http.interceptors.request.use(injectSource, (error) => {
      Promise.reject(error);
    });

    http.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config, response } = error;

        if (isRetryRequired(error)) {
          config.__retryCount += 1;

          const axiosRetry = new Promise((resolve) => {
            setTimeout(() => {
              resolve(axios(config));
            }, (config.retryDelay || API_RETRY_CONFIG.RETRY_DELAY) * config.__retryCount);
          });
          return axiosRetry.then(() => this.exec(config));
        }

        if (error.code === "ERR_NETWORK") {
          return showErrorMessage("No Internet Connection");
        }

        return this.handleError(response, config);
      }
    );

    this.instance = http;
    return http;
  }

  checkExipre(res) {
    const msg = res?.data?.details?.message;
    if (res?.status == 401 && msg == "Token is expired") {
      clearAllCookie();
    }
  }

  request(config) {
    return this.http.request(config);
  }

  exec(config) {
    const { method, url, data, ...rest } = config;
    switch (method.toUpperCase()) {
      case HTTP_METHOD_GET:
        return this.get(url, { ...rest, ...data });
      case HTTP_METHOD_POST:
        return this.post(url, data, rest);
      case HTTP_METHOD_PUT:
        return this.put(url, data, rest);
      case HTTP_METHOD_DELETE:
        return this.delete(url, { ...rest, ...data });
      default:
        return null;
    }
  }

  get(url, config) {
    return this.http.get(url, config);
  }

  post(url, data, config) {
    return this.http.post(url, data, config);
  }

  put(url, data, config) {
    return this.http.put(url, data, config);
  }

  delete(url, config) {
    return this.http.delete(url, config);
  }
  upload(url, data = {}, config) {
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    let header = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config,
    };
    return this.post(url, formData, header);
  }

  handleError(error, config) {
    if (error) {
      const { status } = error;
      if (config?.["showErrorToast"] === false) {
        return Promise.reject(error);
      }
      switch (status) {
        case statusCode.InternalServerError: {
          //  InternalServerError
          showErrorMessage(error?.data?.message);
          break;
        }
        case statusCode.Forbidden: {
          //  Forbidden
          showErrorMessage(error?.data?.message);
          break;
        }
        case statusCode.Unauthorized: {
          //  Unauthorized
          showErrorMessage(error?.data?.message);
          this.checkExipre(error);
          break;
        }
        case statusCode.TooManyRequests: {
          //  TooManyRequests
          break;
        }
        case statusCode.BadRequest:
          {
            if (
              error?.data?.error_code == "F-101" ||
              error?.data?.error_code == "UA-101"
            ) {
              store.dispatch("setAuthenticationMessage", {
                message: error?.data?.message,
                error_code: error?.data?.error_code,
              });
            } else {
              showErrorMessage(error?.data?.message);
              store.dispatch("setAuthenticationMessage", {
                message: "",
                error_code: "",
              });
            }
          }
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  }
}

const http = new HttpClient({
  "X-requestId": getCookieValue("requestId") || getGUID(),
  // "X-cleverTapId": getCookieValue("cleverTapId") || "1234",
  // "X-appsFlyerId": getCookieValue("appsFlyerId") || "1234",
  // "X-source": getCookieValue("source") || "spark-web",
  // "X-appVersion": getCookieValue("appVersion") || "123",
  "X-platform": getCookieValue("platform") || "Web",
  // "X-device": getCookieValue("device") || "123",
  // "X-deviceId": getCookieValue("deviceId") || "123",
  // "X-deviceOS": getCookieValue("deviceOS") || "123",
  // "X-macAddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
  // "X-ipAddress": getCookieValue("ipAddress") || "1.1.1.1",
  // "X-location": getCookieValue("location") || "Bangalore",
});

function getGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export {
  http,
  HttpClient,
  // getCookie,
  setCookie,
  getCookieValue,
  showErrorMessage,
  showInfoMessage,
  deleteCookie,
  clearAllCookie,
  getGUID,
  deleteCookieWithDomain,
};

//example for uupload get progress value
// For Get
// const config = {
//   onUploadProgress: function(progressEvent) {
//     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
//     console.log(percentCompleted)
//   }
// }

//example for uupload get progress value end

// function convertOctateToKnownfileFormat(dataURI = "", dataTYPE = "") {
//   var binary = atob(dataURI.split(",")[1]),
//     array = [];
//   for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
//   return new Blob([new Uint8Array(array)], { type: dataTYPE });
// }
